import { getIssueDate } from "../services/webserver.js";
import createClient from "../utils/createParty.js";
export default (data = {}) => {
    return {
        account: null,
        store: null,
        userId: null,
        agent: null,
        doc_type: null,
        doc_code: null,
        issue_date: null,
        qty: 0,
        total: 0,
        note: null,
        pos: createClient(),
        party: createClient(),
        items: [],
        payments: [],
        delivery_date: getIssueDate(-1),
        delivery_time: null,
        status: 'PENDIENTE',
        ...data
    }
}